import Vue from 'vue'
import App from './App.vue'
import store from './store';
import AuthenticationManager from './services/AuthenticationManager';
import UserService from './services/UserService';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';

// Routes
import Trainings from './Trainings'
import Registration from './Registration'
import Login from './Login'
import PrivacyPolicy from './PrivacyPolicy'
import NotFound404 from './NotFound404'

Vue.config.productionTip = false;

Vue.use(VueRouter);

const routes = [
    { name: 'trainings', path: '/', component: Trainings },
    { name: 'registration', path: '/registreren', component: Registration, meta: { unauthenticated: true, authenticated: false } },
    { name: 'login', path: '/login', component: Login, meta: { unauthenticated: true, authenticated: false } },
    { name: 'privacyPolicy', path: '/privacy-policy', component: PrivacyPolicy, meta: { authenticated: false } },
    { name: 'notFound404', path: '*', component: NotFound404 },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticatedRoute = !Object.prototype.hasOwnProperty.call(to.meta, 'authenticated') ? true : to.meta.authenticated;
    const isUnauthenticatedRoute = !Object.prototype.hasOwnProperty.call(to.meta, 'unauthenticated') ? false : to.meta.unauthenticated;

    if (window.location.search.includes('originator')) {
        localStorage.setItem(store.getters.localStorageKeys.origin, window.location.search.includes('originator'));
        store.dispatch('setOrigin', origin);
    }

    if (window.location.search.includes('access_token') || window.location.search.includes('token')) {
        AuthenticationManager.authenticateByToken().then((isAuthenticated) => {
            window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);

            if (isAuthenticated) {
                return next({ name: 'trainings' });
            } else {
                return next({ name: 'registration' });
            }
        });
    } else {
        AuthenticationManager.isAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                if (isUnauthenticatedRoute) {
                    // Go to authentication route index
                    return next({ name: 'trainings' });
                }

                if (!UserService.userIsLoaded()) {
                    // Load user
                    UserService.fetchLoggedInUser();
                }

                next();
            } else {
                // Is not authenticated
                if (isAuthenticatedRoute) {
                    // Go to training portal
                    return next({ name: 'registration' });
                } else {
                    next();
                }
            }
        });
    }
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
