import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.orange.darken1, // #E53935
                secondary: colors.orange.lighten4, // #FFCDD2
                accent: colors.teal.base, // #3F51B5
            },
            // dark: {
            //     primary: colors.orange.darken1, // #E53935
            //     secondary: colors.orange.lighten4, // #FFCDD2
            //     accent: colors.teal.base, // #3F51B5
            // },
        },
    },
});
