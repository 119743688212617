<template>
    <v-main>
        <v-container class="pt-md-6" fluid>
            <v-row align="start" justify="center" class="mt-6">
                <v-col cols="12" sm="11" md="10" xl="6">
                    <v-card>
                      <v-tabs v-model="tab">
                        <v-tabs-slider color="orange darken-3"></v-tabs-slider>
                        <v-tab>Algemeen</v-tab>
                        <v-tab>Extra</v-tab>
                        <v-tab :disabled="0 === finishedTrainings.length">Afgerond</v-tab>
<!--                        <v-tab>Binnenkort beschikbaar</v-tab>-->
                      </v-tabs>
                    </v-card>
                </v-col>
            </v-row>

            <v-row align="start" justify="center" class="mt-6">
                <v-col cols="12" sm="11" md="10" xl="6">
                    <v-row align="center" justify="start">
                        <template v-for="training in visibleTrainings">
                            <v-col cols="12" sm="6" md="6" lg="6" xl="4" :key="training.id">
                                <TrainingCard v-bind:training="training" v-bind:token="token"/>
                            </v-col>
                        </template>

                        <template v-if="!visibleTrainings.length">
                            <v-col cols="12" sm="6" offset-sm="3" md="6" offset-md="3" lg="6" offset-lg="3" xl="4" offset-xl="4">
                                <v-card elevation="2" class="my-2">
                                    <template v-if="tab === 0">
                                      <v-card-title>Geen trainingen in dit overzicht</v-card-title>
                                      <v-card-subtitle>Het lijkt erop dat je alle trainingen al hebt <a v-on:click="tab = 2" href="javascript:void(0);">afgerond</a>.</v-card-subtitle>
                                    </template>
                                    <template v-if="tab === 1">
                                      <v-card-title>Geen trainingen in dit overzicht</v-card-title>
                                      <v-card-subtitle>Er zijn op dit moment geen extra trainingen.</v-card-subtitle>
                                    </template>
                                    <template v-if="tab === 2">
                                      <v-card-title>Geen trainingen in dit overzicht</v-card-title>
                                      <v-card-subtitle>Je hebt nog geen trainingen afgerond. Ga naar <a v-on:click="tab = 0" href="javascript:void(0);">algemeen</a> en <a v-on:click="tab = 1" href="javascript:void(0);">extra</a> om de beschikbare trainingen te bekijken.</v-card-subtitle>
                                    </template>
                                    <template v-if="tab === 3">
                                      <v-card-title>Geen trainingen in dit overzicht</v-card-title>
                                      <v-card-subtitle>Er staan op dit moment geen nieuwe trainingen in de planning.</v-card-subtitle>
                                    </template>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="text-center" justify="center">
                <v-col class="mt-6 mt-lg-12" cols="12" sm="11" md="10" xl="6">
                    <p class="subheading font-weight-regular" v-if="loggedInUser && loggedInUser.sso">
                        <v-btn color="primary" text class="d-none d-sm-flex" v-bind:href="origin">Klik hier om terug te keren naar het Leaseplan portaal</v-btn>
                        <v-btn color="primary" text class="d-flex d-sm-none" v-bind:href="origin"><v-icon left>mdi-link-variant</v-icon> Leaseplan portaal</v-btn>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>


<script>
import TrainingCard from './components/TrainingCard';

export default {
    name: 'App',

    components: {
        TrainingCard,
    },

    data: () => ({
        onResizeTimeout: null,
        resizeCardsSingleTimeout: null,
        resizeCardsInterval: null,
        tab: null,
    }),

    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        },
        origin() {
            return this.$store.getters.origin;
        },
        trainings() {
            return this.$store.getters.trainings;
        },
        unfinishedTrainings() {
            return this.$store.getters.unFinishedTrainings;
        },
        finishedTrainings() {
            return this.$store.getters.finishedTrainings;
        },
        visibleTrainings() {
            switch (this.tab) {
                case 0:
                    return this.$store.getters.availableTrainings;
                case 1:
                    return this.$store.getters.extraTrainings;
                case 2:
                    return this.$store.getters.finishedTrainings;
                case 3:
                    return this.$store.getters.soonAvailableTrainings;
                default:
                    return [];
            }
        },
        token() {
            let oauth = this.$store.getters.oauth;
            return oauth ? oauth.access_token : '';
        },
    },

    mounted() {
        window.addEventListener('resize', this.onResize);

        this.resizeCardsInterval = setInterval(() => {
            this.resizeCards();
        }, 1000);
        this.resizeCardsSingleTimeout = setTimeout(() => {
            this.resizeCards();
        }, 100);

        this.resizeCards();

        this.getTrainingsProgress();
    },

    beforeDestroy: function () {
        window.removeEventListener('resize', this.onResize);
        clearInterval(this.resizeCardsInterval);
        clearTimeout(this.resizeCardsSingleTimeout);
    },

    methods: {
        onResize() {
            if (!this.onResizeTimeout) {
                this.onResizeTimeout = setTimeout(() => {
                    this.resizeCards();
                    this.onResizeTimeout = null;
                }, 50);
            }
        },

        resizeCards() {
            let cardElements = document.getElementsByClassName('card-training');
            let cardSize,
                largestCardSize = 0;

            if (600 <= window.outerWidth) {
                for (let i = 0; i < cardElements.length; i++) {
                    cardSize = 0;
                    cardSize += cardElements[i].querySelector('.v-image').offsetHeight;
                    cardSize += cardElements[i].querySelector('.v-card__title').offsetHeight;
                    cardSize += cardElements[i].querySelector('.v-card__subtitle').offsetHeight;
                    cardSize += cardElements[i].querySelector('.v-card__actions').offsetHeight;

                    cardSize -= 30;

                    if (largestCardSize < cardSize) {
                        largestCardSize = cardSize;
                    }
                }
            }

            for (let i = 0; i < cardElements.length; i++) {
                cardElements[i].style.minHeight = largestCardSize + 'px';
            }
        },

        getTrainingsProgress() {
            let trainingKeys = '';

            this.trainings.forEach((training) => {
                trainingKeys += (trainingKeys ? ',' : '') + training.id;
            });

            this.$store.dispatch('jv/get', [`tests`, { params: {
                    'criteria[training-variants]': trainingKeys,
                    limit: 100,
                }}]).then((data) => {
                let tests = [];

                Object.keys(data).forEach(key => {
                    tests.push({
                        id: data[key]['_jv']['id'],
                        trainingVariant: data[key]['_jv']['relationships']['training-variant']['data']['id'],
                        finishedAt: data[key]['finished-at'],
                        slidesCount: data[key]['slides-count'],
                        finishedSlidesCount: data[key]['finished-slides-count'],
                    });
                });

                this.$store.dispatch('setTests', tests);
            });
        },
    }
};
</script>