<template>
    <v-app v-bind:class="$route.name">
        <v-app-bar v-if="loggedInUser" app>
            <div class="d-flex align-center">
              <v-img alt="Leaseplan" class="mt-7 mt-md-9" contain transition="scale-transition" src="/images/leaseplan-logo.png" />
              <h3 class="font-weight-regular hidden-xs-only">Trainingen</h3>
            </div>

            <v-spacer></v-spacer>

            <v-menu v-if="loggedInUser" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text>
                        <v-icon class="mr-2" size="x-large">mdi-account-circle</v-icon>
                        <span class="hidden-xs-only">{{loggedInUser.name}}</span>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item class="hidden-sm-and-up">
                        <v-list-item-title>
                            <v-icon>mdi-account-circle</v-icon>
                            {{loggedInUser.name}}
                        </v-list-item-title>
                    </v-list-item>

                    <template v-if="isPartialUser">
                        <v-divider class="hidden-sm-and-up"></v-divider>
                        <v-list-item v-on:click="redirectToOrigin">
                            <v-list-item-title>
                                <v-icon>mdi-link-variant</v-icon>
                                Leaseplan portaal
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                    <template v-else>
                        <v-list-item v-on:click="isShowingMyAccountModal = true">
                            <v-list-item-title>
                                <v-icon>mdi-account</v-icon>
                                Mijn account
                            </v-list-item-title>
                        </v-list-item>
                    </template>

                    <v-list-item  v-on:click="logout">
                        <v-list-item-title>
                            <v-icon>mdi-logout</v-icon>
                            Uitloggen
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <router-view></router-view>

        <v-dialog v-model="isShowingMyAccountModal" max-width="400">
            <v-card>
                <v-card-title class="headline">Mijn account</v-card-title>
                <v-card-text>
                    <template v-if="!isChangingName && !isChangingPassword">
                        <h3>Naam</h3>
                        <p class="fs-18" v-if="loggedInUser">
                            {{loggedInUser.name}}
                            <v-btn color="primary" text @click="onChangeNameClick">wijzigen</v-btn>
                        </p>

                        <template v-if="canChangePassword">
                            <h3>Wachtwoord</h3>
                            <p class="fs-18">
                                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                                <v-btn color="primary" text @click="onChangePasswordClick">wijzigen</v-btn>
                            </p>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="isChangingName">
                            <v-text-field v-model="newName" ref="formName" type="text" prepend-icon="mdi-account-outline" label="Voor en achternaam" :disabled="isSubmitting" :rules="nameRules" :validate-on-blur="!isFormNameValid" required></v-text-field>
                        </template>
                        <template v-if="isChangingPassword">
                            <v-text-field v-model="newPassword" ref="formPassword" type="password" prepend-icon="mdi-fingerprint" label="Nieuw wachtwoord" :disabled="isSubmitting" :rules="passwordRules" :validate-on-blur="!isFormPasswordValid" required></v-text-field>
                            <label>Het wachtwoord moet minimaal 8 tekens lang zijn en een letter en een cijfer bevatten.</label>
                        </template>
                    </template>
                </v-card-text>

                <v-card-actions v-if="!isChangingName && !isChangingPassword">
                    <v-btn color="primary" block text @click="isShowingMyAccountModal = false">Sluiten</v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                    <v-btn color="primary" text @click="onCancelChangeAccount">Annuleren</v-btn>
                    <v-spacer/>
                    <v-btn color="primary" @click="onChangeClick" :disabled="!(isFormNameValid || isFormPasswordValid) || isSubmitting">Wijzigen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import AuthenticationManager from './services/AuthenticationManager';
import AxiosApi from './services/AxiosApi';

export default {
    name: 'App',

    data: () => ({
        isShowingMyAccountModal: false,
        isChangingName: false,
        isChangingPassword: false,
        isSubmitting: false,
        newName: '',
        newPassword: '',
        isFormNameValid: false,
        isFormPasswordValid: false,
        nameRules: [
            (value) => {
                return !!(value && 5 < value.length && value.indexOf(' ')) || 'Voor + achternaam';
            },
        ],
        passwordRules: [
            (value) => {
                return /^(?=.*[a-z])(?=.*)(?=.*\d).{8,100}$/.test(value) || 'Minimaal 8 tekens en moet een letter en een cijfer bevatten';
            },
        ],
    }),

    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        },
        isPartialUser() {
            return this.loggedInUser && this.loggedInUser.isPartialUser;
        },
        canChangePassword() {
          return this.loggedInUser && !this.loggedInUser.isPartialUser;
        },
        origin() {
            return this.$store.getters.origin;
        },
        trainings() {
          return this.$store.getters.trainings;
        },
    },

    watch: {
        newName() {
            this.$nextTick(() => {
                if (!Object.prototype.hasOwnProperty.call(this.$refs, 'formName') || !this.isChangingName) {
                    this.isFormNameValid = false;
                } else {
                    this.isFormNameValid = this.$refs.formName.validate();
                }
            });
        },
        newPassword() {
            this.$nextTick(() => {
                if (!Object.prototype.hasOwnProperty.call(this.$refs, 'formPassword') || !this.isChangingPassword) {
                    this.isFormPasswordValid = false;
                } else {
                    this.isFormPasswordValid = this.$refs.formPassword.validate();
                }
            });
        },
    },

    methods: {
        logout() {
            let isPartialUser = this.loggedInUser && this.loggedInUser.isPartialUser;

            AuthenticationManager.invalidateSession();

            if (!isPartialUser) {
                this.$router.push({ name: 'login' });
            } else {
                window.location.replace(this.origin);
            }
        },
        onChangeClick() {
            if (this.isSubmitting) {
                return;
            }

            this.isSubmitting = true;

            if (this.isChangingName) {
                // Save name
                AxiosApi.request({
                    url: '/api/update-name',
                    method: 'post',
                    data: {
                        name: this.newName,
                    }
                }).then(() => {
                    // ok
                    this.loggedInUser.name = this.newName;
                    this.newName = '';
                    this.isChangingName = false;
                }).finally(() => {
                    this.isSubmitting = false;
                });
            } else if (this.isChangingPassword) {
                // Save password
                AxiosApi.request({
                    url: '/api/update-password',
                    method: 'post',
                    data: {
                        password: this.newPassword,
                    }
                }).then(() => {
                    // ok
                    this.newPassword = '';
                    this.isChangingPassword = false;
                }).finally(() => {
                    this.isSubmitting = false;
                });
            }
        },
        onChangeNameClick() {
            this.newName = this.loggedInUser.name;
            this.isChangingName = true;
        },
        onChangePasswordClick() {
            this.newPassword = '';
            this.isChangingPassword = true;
        },
        onCancelChangeAccount() {
            this.isChangingName = false;
            this.isChangingPassword = false;
            this.newName = '';
            this.newPassword = '';
        },

        redirectToOrigin() {
            window.location.replace(this.$store.getters.origin);
        },
    }
};
</script>

<style>
    html, body, #app {
        background-color: #f6f8fb;
    }

    body > .v-application.login, body > .v-application.registration {
        /*overflow-y: auto;*/
        /*height: 100%;*/
        background: url('/images/background-driving.jpg') no-repeat center center !important;
        background-size: cover !important;
    }
    .well {
        padding: 12px;
        border: 1px solid #e4e4e4;
        border-radius: 3px;
        background: #fbfbfb;
        margin: 12px 0 10px;
        font-size: 15px;
        animation: fadein .5s;
        -moz-animation: fadein .5s; /* Firefox */
        -webkit-animation: fadein .5s; /* Safari and Chrome */
        -o-animation: fadein .5s; /* Opera */
    }
    .well-info {
        background: #f6f8fb;
    }
    .well i.v-icon {
        margin-top: -1px;
    }
    p.icon-left {
        position: relative;
        padding-left: 72px;
    }
    p.icon-left i.v-icon {
        font-size: 36px;
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -18px;
    }
</style>