<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="11" md="7" xl="4">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <v-card-text class="px-6">
                            <h2 class="elektrisch-rijden">Welkom bij het Leaseplan trainingen portaal</h2>

                            <p class="elektrisch-rijden mt-2">
                                Heb je al een Leaseplan account? Log dan in via het <a v-bind:href="origin" title="Leaseplan portaal">Leaseplan portaal</a>. Via die manier blijven je accounts gekoppeld. Heb je die niet? Registreer je dan hier.
                            </p>

                            <p class="mt-2">
                                Heb je al een account? <router-link :to="{ name: 'login' }">Log dan hier in.</router-link>
                            </p>

                            <v-form v-if="!isFormSubmitted" v-model="isFormValid" v-on:submit="onFormSubmit" ref="form" class="mb-2">
                                <p v-if="emailExists" class="well well-info mb-4"><v-icon>mdi-information-variant</v-icon> Dit emailadres bestaat al. <router-link :to="{ name: 'login', query: { email: email }}">Je kunt hier inloggen.</router-link></p>
                                <p v-else-if="formError" class="well well-info mb-4">{{formError}}</p>

                                <v-text-field v-model="name" ref="formName" type="text" prepend-icon="mdi-account-outline" label="Voor- en achternaam" :disabled="isSubmittingForm" :rules="nameRules" :validate-on-blur="!isFormNameValid" required></v-text-field>

                                <v-text-field v-model="email" ref="formEmail" type="email" prepend-icon="mdi-email-outline" label="E-mailadres" :disabled="isSubmittingForm" :rules="emailRules" :validate-on-blur="!isFormEmailValid" required></v-text-field>

                                <v-text-field v-model="password" ref="formPassword" type="password" prepend-icon="mdi-fingerprint" label="Nieuw wachtwoord" :disabled="isSubmittingForm" :rules="passwordRules" :validate-on-blur="!isFormPasswordValid" required></v-text-field>

                                <v-checkbox v-model="agreedWithPrivacyPolicy" color="primary" :disabled="isSubmittingForm" required>
                                    <span slot="label">Ik ga akkoord met de <router-link :to="{ name: 'privacyPolicy' }">privacy policy</router-link> van VVCR-Prodrive</span>
                                </v-checkbox>

                                <div>
                                    <v-btn type="submit" color="primary" depressed large block :disabled="!isFormValid || !agreedWithPrivacyPolicy || isSubmittingForm || isFormSubmitted || emailExists">Registreren</v-btn>
                                    <div class="mt-4 mb-1 text-center">
                                        <router-link :to="{ name: 'login' }">Heb je al een account? Inloggen kan hier.</router-link>
                                    </div>
                                </div>
                            </v-form>
                            <div v-else>
                                <p class="well well-info icon-left">
                                    <v-icon large>mdi-email-outline</v-icon>
                                    <span>Je bent nu geregistreerd. In je inbox ontvang je een e-mail met een bevestigingslink om je e-mailadres te verifi&#235;ren. Na de verificatie kun je inloggen. Let op: ontvang je de mail niet direct? Geen paniek. Het kan tot maximaal 5 minuten duren voordat u de mail ontvangt. Houd ook je spam-box in de gaten.</span>
                                </p>
                                <v-btn text large block color="primary" class="mt-2" :to="{ name: 'login', query: { email: email }}">Inloggen</v-btn>
                            </div>
                        </v-card-text>

                        <v-progress-linear v-if="isSubmittingForm" indeterminate color="primary"></v-progress-linear>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';
import AxiosApi from './services/AxiosApi';
import AuthenticationManager from './services/AuthenticationManager';
import UserService from './services/UserService';

export default {
    components: {
        HeaderImage,
    },

    watch: {
        name () {
            this.isFormNameValid = this.$refs.formName.validate();
        },
        email (email) {
            this.isFormEmailValid = this.$refs.formEmail.validate();

            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
                this.checkIfEmailExists(email);
            }
        },
        password () {
            this.isFormPasswordValid = this.$refs.formPassword.validate();
        }
    },

    data: () => ({
        name: null,
        email: null,
        password: null,
        agreedWithPrivacyPolicy: false,
        isFormValid: false,
        isSubmittingForm: false,
        isFormSubmitted: false,
        emailExists: false,
        formError: null,
        isFormNameValid: false,
        isFormEmailValid: false,
        isFormPasswordValid: false,
        nameRules: [
            (value) => {
                return !!(value && 5 < value.length && value.indexOf(' ')) || 'Voor + achternaam';
            },
        ],
        emailRules: [
            (value) => {
                // return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value) || 'Geen geldig email adres';
                return /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(leaseplan|vvcr|prodriveacademy|vvcr-prodrive)\.(nl|com)$/.test(value) || 'Dit is geen geldig Leaseplan email adres';
            },
        ],
        passwordRules: [
            (value) => {
                return /^(?=.*[a-z])(?=.*)(?=.*\d).{8,100}$/.test(value) || 'Minimaal 8 tekens en moet een letter en een cijfer bevatten';
            },
        ],
    }),

    computed: {
        origin() {
            return this.$store.getters.origin;
        },
    },

    created () {
        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'email')) {
            this.email = this.$route.query.email;
        }
        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'name')) {
            this.email = this.$route.query.name;
        }
    },

    destroyed () {
        this.email = null;
        this.emailExists = false;
        this.password = null;
    },

    methods: {
        onFormSubmit (e) {
            if (e) {
                e.preventDefault();
            }

            this.formError = null;
            this.$refs.form.validate();

            if (!this.isFormValid) {
                return;
            }

            this.isSubmittingForm = true;

            UserService.unloadUser();

            AuthenticationManager.register({
                name: this.name,
                email: this.email,
                plainPassword: this.password,
            }).then(() => {
                this.isFormSubmitted = true;
            }).catch((error) => {
                this.formError = error.response;
                window.console.log(error, error.response);
            }).finally(() => {
                this.isSubmittingForm = false;
            });

            return false;
        },

        checkIfEmailExists(email) {
            AxiosApi.request({
                url: 'user-exists',
                method: 'post',
                data: {
                    email: email,
                }
            }).then((response) => {
                this.emailExists = response.data.exist;
            });
        },
    }
};
</script>

<style scoped>
    .v-input--selection-controls.v-input--checkbox {
        margin-top: 10px;
    }
</style>