import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { jsonapiModule } from 'jsonapi-vuex'
import AuthenticationManager from './services/AuthenticationManager';

Vue.use(Vuex);

const api = axios.create({
    // baseURL: '//api.prodrive-elearning.test/api',
    baseURL: 'https://api.prodrive-elearning.defineweb.nl/api',
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
});

let store = new Vuex.Store({
    debug: true,
    modules: {
        jv: jsonapiModule(api),
    },
    state: {
        localStorageKeys: {
            token: 'token',
            origin: 'origin',
        },
        oauth: null,
        token: null,
        origin: 'https://mijn.leaseplan.nl/',
        loggedInUser: null,
        tests: null,
        trainings: [
            {
                id: 66,
                name: "Hoe zit het ook alweer",
                description: "Rond je 18e haalde je waarschijnlijk je rijbewijs, deze trainingen brengen jou weer helemaal op de hoogte van de huidige verkeersregels- en etiquette.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://hoezithetookalweer.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {
                id: 67,
                name: "Welke snelheid geldt hier?",
                description: "100, 120, 130. Hoe hard mag je precies rijden en hoe zorg je dat je dit altijd weet?",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://welkesnelheidgeldthier.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {
                id: 68,
                name: "Veilig en efficient onderweg",
                description: "In deze module leer je risico’s kunt verlagen maar toch tijdig op je bestemming kunt komen.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://veiligenefficientonderweg.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {
                id: 29,
                name: "Speedpedelec",
                description: "Tips over veilig rijden en ongelukken voorkomen met e-bikes en speed pedelecs.",
                isFinished: false,
                isActive: true,
                isExtra: true,
                duration: 20,
                link: 'https://speedpedelec.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {
                id: 30,
                name: "Bestelauto",
                description: "Bestelauto's rijden anders dan personenauto's. Dat weten de meesten wel. Maar hoe pas je je rijgedrag hier op aan?",
                isFinished: false,
                isActive: true,
                isExtra: true,
                duration: 20,
                link: 'https://bestelauto.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {
                id: 10,
                name: "Elektrisch rijden",
                description: "Elektrisch rijden, niet moeilijk, maar wel anders. Met deze training ga je van \"range anxiety\" naar \"range enthousiasm\".",
                isFinished: false,
                isActive: true,
                isExtra: true,
                duration: 10,
                link: 'https://elektrisch-rijden.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 april
                id: 71,
                name: "Rijhulpsystemen",
                description: "In deze module kom je meer te weten over de meestgebruikte rijhulpsystemen.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://rijhulpsystemen.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 april
                id: 77,
                name: "Hoe gebruik jij je smartphone",
                description: "Moet de smartphone de auto uit? Nee! Hij kan je zelfs helpen bij een veilige rit.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://hoegebruikjijjesmartphone.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 april
                id: 78,
                name: "Laat jij je afleiden",
                description: "Afleiding in het verkeer is de grootste boosdoener van ongelukken. In deze module leer je afleiding te vermijden.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://laatjijjeafleiden.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 juni
                id: 69,
                name: "Gevaarherkenning",
                description: "In deze module leer je potentieel gevaar herkennen voor het daadwerkelijk gevaarlijk wordt.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://gevaarherkenning.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 juni
                id: 70,
                name: "Veilig inhalen en waarnemen",
                description: "In deze module leer je de stappen van veilig inhalen.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://veiliginhalenenwaarnemen.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 juni
                id: 75,
                name: "Het gevaar van automatisme",
                description: "De meeste ongelukken ontstaan 5 minuten na vertrek of 5 minuten voor aankomt. Wij vertellen je waarom.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://hetgevaarvanautomatisme.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 oktober
                id: 73,
                name: "Voorrang verlenen",
                description: "We geven je de tips en tricks van defensief rijden.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://voorrangverlenen.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 oktober
                id: 74,
                name: "Houd gevaar op afstand",
                description: "In deze module herinneren we je aan de gevaren van te weinig afstand houden met je voorligger.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://houdgevaaropafstand.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            {   // From 1 oktober
                id: 76,
                name: "Schade voorkomen",
                description: "Hoe verklein je de kans op schade’s? Bekijk deze module en je leert er alles over.",
                isFinished: false,
                isActive: true,
                isExtra: false,
                duration: 10,
                link: 'https://schadevoorkomen.leaseplan.vvcr-prodrive.nl',
                tests: [],
            },
            // {
            //     id: 30,
            //     name: "Bestelauto",
            //     description: "Bestelauto's rijden anders dan personenauto's. Dat weten de meesten wel. Maar hoe pas je je rijgedrag hier op aan?",
            //     isFinished: false,
            //     isActive: true,
            //     isExtra: false,
            //     duration: 20,
            //     link: 'https://bestelauto.leaseplan.vvcr-prodrive.nl',
            //     tests: [],
            // }, {
            //     id: 11,
            //     name: "Top 3 schadeoorzaken",
            //     description: "Niemand rijdt met opzet schade. Als je weet hoe de meeste schade’s worden veroorzaakt kan je ze ook voorkomen.",
            //     isFinished: false,
            //     isActive: true,
            //     isExtra: false,
            //     duration: 20,
            //     link: 'https://schadeoorzaken.leaseplan.vvcr-prodrive.nl',
            //     tests: [],
            // },
            // , {
            //     id: 12,
            //     name: "Verkeersinzicht",
            //     description: "Als jij weet welke situaties gevaren kunnen veroorzaken laat je je niet meer verassen. Vergroot je inzicht met deze training.",
            //     isFinished: false,
            //     isActive: true,
            //     isExtra: false,
            //     duration: 15,
            //     link: 'https://verkeersinzicht.leaseplan.vvcr-prodrive.nl',
            //     tests: [],
            // }, {
            //     id: 13,
            //     name: "Nieuwe verkeersregels",
            //     description: "Is de snelheid op de matrix borden nou een advies of een maximum snelheid en wat betekenen groene lijnen nu weer? Na deze module ben je weer helemaal op de hoogte.",
            //     isFinished: false,
            //     isActive: true,
            //     isExtra: false,
            //     duration: 8,
            //     link: 'https://verkeersregels.leaseplan.vvcr-prodrive.nl',
            //     tests: [],
            // }
        ]
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setOrigin(state, origin) {
            state.origin = origin;
        },
        setOauth(state, oauth) {
            state.oauth = oauth;
        },
        setLoggedInUser(state, user) {
            user['sso'] = !user.email.includes('@');
            state.loggedInUser = user;
        },
        setTrainings(state, trainings) {
            state.trainings = trainings;
        },
        setTests(state, tests) {
            state.tests = tests;
        },
        invalidateSession(state) {
            state.oauth = null;
            state.loggedInUser = null;
        },
        unloadUser(state) {
            state.loggedInUser = null;
        },
    },
    actions: {
        setToken(state, token) {
            if (token) {
                api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
            this.commit('setToken', token);
        },
        setOrigin(state, origin) {
            this.commit('setOrigin', origin);
        },
        setTests(state, tests) {
            let trainings = state.getters.trainings;

            trainings.forEach((training) => {
                training.tests = tests.filter(test => parseInt(test.trainingVariant) === parseInt(training.id));
                training.isFinished = !!training.tests.find(test => test.finishedAt);
            });

            this.commit('setTests', tests);
            this.commit('setTrainings', trainings);
        },
        setOAuth(state, oauth) {
            if (oauth && Object.prototype.hasOwnProperty.call(oauth, 'access_token')) {
                api.defaults.headers.common['Authorization'] = `Bearer ${oauth.access_token}`;
            }
            this.commit('setOauth', oauth);
        },
        invalidateSession() {
            api.defaults.headers.common['Authorization'] = null;
            this.commit('invalidateSession');
        },
        setLoggedInUser(state, user) {
            this.commit('setLoggedInUser', user);
        },
        unloadUser() {
            this.commit('unloadUser');
        }
    },
    getters: {
        token: state => {
            return state.token;
        },
        origin: state => {
            return state.origin;
        },
        localStorageKeys: state => {
            return state.localStorageKeys;
        },
        trainings: state => {
            return state.trainings;
        },
        finishedTrainings: state => {
            return state.trainings.filter((training) => {
                return training.isFinished;
            });
        },
        unFinishedTrainings: state => {
            return state.trainings.filter((training) => {
                return !training.isFinished;
            });
        },
        availableTrainings: state => {
            return state.trainings.filter((training) => {
                return !training.isFinished && training.isActive && !training.isExtra;
            });
        },
        extraTrainings: state => {
            return state.trainings.filter((training) => {
                return training.isExtra;
            });
        },
        soonAvailableTrainings: state => {
            return state.trainings.filter((training) => {
                return !training.isActive;
            });
        },
        test: state => id => {
            return state.tests.filter(test => test.trainingVariant.id === id);
        },
        oauth: state => {
            return state.oauth;
        },
        loggedInUser: (state) => () => {
            return state.loggedInUser;
        },
    }
});

api.interceptors.response.use(response => response, (error) => {
    if (401 === error.response.status) {
        AuthenticationManager.invalidateSession();

        localStorage.removeItem(store.getters.localStorageKeys.token);
        localStorage.removeItem(store.getters.localStorageKeys.origin);

        if (!store.state.loggedInUser.isPartialUser) {
            window.location.replace(window.location.protocol + "//" + window.location.host + "/login");
        } else {
            window.location.replace(store.getters.origin);
        }

    }
    return Promise.reject(error);
});

export default store;