<template>
    <div class="text-center">
        <v-divider></v-divider>
        <img class="header-image" src="/images/leaseplan-logo.png" />
    </div>
</template>

<script>
export default {
    name: 'HeaderImage',
};
</script>

<style scoped>
    img.header-image {
        width: 178px;
        z-index: 2;
        position: relative;
    }
    hr.v-divider {
        position: absolute;
        width: 100%;
        top: 52px;
        z-index: 1;
    }
</style>