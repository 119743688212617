<template>
    <v-hover v-slot:default="{ hover }">
        <v-card :elevation="hover ? 4 : 2" v-bind:class="`my-2 card-training ${!training.isActive?'semi-disabled':''}`">
<!--        <v-card :elevation="hover ? 4 : 2" class="my-2 card-training" :disabled="!training.isActive">-->
            <span class="available-soon" v-if="!training.isActive">Binnenkort beschikbaar</span>
            <v-img height="200" v-bind:src="`/images/training${training.id}.jpg`"></v-img>
            <v-card-title>
                {{training.name}}
                <v-icon right v-if="training.isFinished" class="primary--text" title="Deze training heb je afgerond">mdi-check-circle</v-icon>
            </v-card-title>
            <v-card-subtitle>{{training.description}}</v-card-subtitle>
            <v-card-actions>
                <v-btn v-if="!training.isActive" disabled text block><v-icon left>mdi-clock</v-icon> Binnenkort beschikbaar</v-btn>
<!--                <v-btn v-if="!training.isActive" text block v-bind:href="training.link + '?token=' + token"><v-icon left>mdi-clock</v-icon> Binnenkort beschikbaar</v-btn>-->
                <v-btn v-else-if="!training.isFinished" text block color="primary" v-bind:href="training.link + '?token=' + token"><v-icon left>mdi-play</v-icon> Training starten</v-btn>
                <v-btn v-else text block color="accent" v-bind:href="training.link + '?token=' + token"><v-icon left>mdi-refresh</v-icon> Nogmaals maken</v-btn>
            </v-card-actions>
        </v-card>

<!--        <v-card v-bind:href="training.link + '?token=' + token" :elevation="hover ? 4 : 2" :class="`training ${training.isFinished ? 'finished' : ''} mx-auto chapter-card d-flex`">-->
<!--            <v-avatar contain tile>-->
<!--                <v-img src="https://imgcomfort.com/Userfiles/Upload/images/illustration-geiranger.jpg"></v-img>-->
<!--            </v-avatar>-->

<!--            <div>-->
<!--                <v-card-title>-->
<!--                    <span v-if="training.title" class="primary">{{ training.title }}</span>-->
<!--                    <span v-else class="orange&#45;&#45;text text&#45;&#45;darken-3">{{ training.name }}</span>-->

<!--                    <v-icon right v-if="training.isFinished" class="green&#45;&#45;text text&#45;&#45;darken-2">mdi-check-circle</v-icon>-->
<!--                </v-card-title>-->

<!--                <v-card-subtitle>{{ training.name }}</v-card-subtitle>-->
<!--            </div>-->
<!--        </v-card>-->
    </v-hover>
</template>

<script>
    export default {
        name: 'TrainingCard',

        props: {
            training: {
                type: Object,
                required: true,
            },
            token: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style scoped>
    .card-training {
        overflow: hidden;
    }

    .card-training.semi-disabled > *:not(.v-card__progress) {
        opacity: 0.6;
        transition: inherit;
    }

    .available-soon {
        position: absolute;
        background-color: #fb8c00;
        color: #fff;
        font-size: 18px;
        transform: rotate(-30deg);
        padding: 5px;
        width: 400px;
        text-align: center;
        opacity: 1 !important;
        z-index: 1;
        left: -90px;
        top: 50px;
        box-shadow: 0 2px 2px rgba(50, 50, 50, 0.3);
    }

    @media only screen and (min-width: 600px) {
        .card-training {
            padding-bottom: 32px !important;
        }
        
        .v-card__actions {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
</style>