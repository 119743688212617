import store from '../store';

const UserService = {
    _isLoading: false,
    fetchUserPromise: null,

    userIsLoaded() {
        return !!store.state.loggedInUser;
    },

    fetchLoggedInUser() {
        this._isLoading = true;

        this.fetchUserPromise = store.dispatch('jv/get', 'users/@me').then((data) => {
            store.dispatch('setLoggedInUser', data);

            return new Promise((resolve) => {
                resolve(store.state.loggedInUser);
            });
        }).finally(() => {
            this._isLoading = false;
        });
    },

    async getLoggedInUser() {
        if (true === this._isLoading) {
            return this.fetchUserPromise;
        } else {
          return new Promise((resolve) => {
              resolve(store.state.loggedInUser);
          });
        }
    },

    unloadUser () {
        store.dispatch('unloadUser');
    }
};

export default UserService;
